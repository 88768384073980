import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AddVendor from '../pages/vendors/components/AddVendor'
import VendorsList from '../pages/vendors/components/VendorsList'
import AddCategory from '../pages/categories/components/AddCategory'
import CategoriesList from '../pages/categories/components/CategoriesList'
import AddSubcategories from '../pages/subcategories/components/AddSubcategories'
import SubcategoriesList from '../pages/subcategories/components/SubcategoriesList'
import AddProduct from '../pages/products/components/AddProduct'
import ProductsList from '../pages/products/components/ProductsList'
import AddRequisitionType from '../pages/requisitions/requisitionsType/components/AddRequisitionType'
import RequisitionTypeList from '../pages/requisitions/requisitionsType/components/RequisitionTypeList'
import AddQuotationType from '../pages/quotations/quotationsType/components/AddQuotationType'
import QuotationsTypeList from '../pages/quotations/quotationsType/components/QuotationsTypeList'
import AddRequisition from '../pages/requisitions/requisitions/components/AddRequisition'
import RequisitionsList from '../pages/requisitions/requisitions/components/RequisitionsList'
import AddQuotation from '../pages/quotations/quotations/components/AddQuotation'
import QuotationsList from '../pages/quotations/quotations/components/QuotationsList'
import VenderLogs from '../pages/vendors/components/VendorLogs'
import AllVendorsLogs from '../pages/vendors/components/AllVendorsLogs'
import RequisitionTypeLogs from '../pages/requisitions/requisitionsType/components/RequisitionTypeLogs'
import AllRequisitionTypesLogs from '../pages/requisitions/requisitionsType/components/AllRequisitionTypesLogs'
import UsersList from '../pages/users/components/UsersList'
import UserLogs from '../pages/users/components/UserLogs'
import AllUsersLogs from '../pages/users/components/AllUsersLogs'
import SubcategoryLogs from '../pages/subcategories/components/SubcategoryLogs'
import AllSubcategoriesLogs from '../pages/subcategories/components/AllSubcategoriesLogs'
import CategoryLogs from '../pages/categories/components/CategoryLogs'
import AllCategoriesLogs from '../pages/categories/components/AllCategoriesLogs'
import ProductLogs from '../pages/products/components/ProductLogs'
import AllProductsLogs from '../pages/products/components/AllProductsLogs'
import RequisitionLog from '../pages/requisitions/requisitions/components/RequisitionLog'
import AllRequisitionsLogs from '../pages/requisitions/requisitions/components/AllRequisitionsLogs'
import QuotationLog from '../pages/quotations/quotations/components/QuotationLog'
import AllQuotationLogs from '../pages/quotations/quotations/components/AllQuotationLogs'
import QuotationTypeLogs from '../pages/quotations/quotationsType/components/QuotationTypeLogs'
import AllQuotationsTypeLogs from '../pages/quotations/quotationsType/components/AllQuotationsTypeLogs'
import EditVendor from '../pages/vendors/components/EditVendor'
import ViewVendor from '../pages/vendors/components/ViewVendor'
import EditProduct from '../pages/products/components/EditProduct'
import ViewProduct from '../pages/products/components/ViewProduct'
import EditUser from '../pages/users/components/EditUser'
import EditRequisition from '../pages/requisitions/requisitions/components/EditRequisition'
import ViewRequisition from '../pages/requisitions/requisitions/components/ViewRequisition'
import EditQuotation from '../pages/quotations/quotations/components/EditQuotation'
import ViewQuotation from '../pages/quotations/quotations/components/ViewQuotation'
import AddUser from '../pages/users/components/AddUser'
import ViewUser from '../pages/users/components/ViewUser'
import AddRole from '../pages/users/roles/components/AddRole'
import RolesList from '../pages/users/roles/components/RolesList'
import EditRole from '../pages/users/roles/components/EditRole'
import AllRolesLogs from '../pages/users/roles/components/AllRolesLogs'
import AddPurchase from '../pages/purchases/components/AddPurchase'
import ViewPurchase from '../pages/purchases/components/ViewPurchase'
import EditPurchase from '../pages/purchases/components/EditPurchase'
import PurchasesList from '../pages/purchases/components/PurchasesList'
import AddNewProduct from '../pages/purchases/components/AddNewProduct'
import ViewVendorLogs from '../pages/vendors/components/ViewVendorLogs'
import AddStockOut from '../pages/stocks/components/AddStockOut'
import AddStockIn from '../pages/stocks/components/AddStockIn'
import AddDepartment from '../pages/departments/components/AddDepartment'
import StockOutList from '../pages/stocks/components/StockOutList'
import EditStockOut from '../pages/stocks/components/EditStockOut'
import ViewStockIn from '../pages/stocks/components/ViewStockIn'
import ViewStockOut from '../pages/stocks/components/ViewStockOut'
import PurchaseLogs from '../pages/purchases/components/PurchaseLogs'
import AllPurchasesLogs from '../pages/purchases/components/AllPurchasesLogs'
import EditStockIn from '../pages/stocks/components/EditStockIn'
import QuotationDocuments from '../pages/quotations/quotations/components/QuotationDocuments'
import DepartmentLogs from '../pages/departments/components/DepartmentLogs'
import AllDepartmentLogs from '../pages/departments/components/AllDepartmentLogs'
import DepartmentsList from '../pages/departments/components/DepartmentsList'
import StockInLogs from '../pages/stocks/components/StockInLogs'
import AllStockInLogs from '../pages/stocks/components/AllStockInLogs'
import StockInList from '../pages/stocks/components/StockInList'
import AllStockOutLogs from '../pages/stocks/components/AllStockOutLogs'
import StockOutLogs from '../pages/stocks/components/StockOutLogs'
import RequisitionDocuments from '../pages/requisitions/requisitions/components/RequisitionDocuments'
import AssetsList from '../pages/productTypes/components/AssetsList'
import ConsumablesList from '../pages/productTypes/components/ConsumablesList'
import { permissionCheck } from '../helper-functions/PermissionCheck'
import AllNotifications from '../../_metronic/partials/layout/header-menus/AllNotifications'
import GeneralSettings from '../pages/general-settings/components/GeneralSettings'
import ProfileUpdate from '../pages/account/components/ProfileUpdate'
import AccountSetting from '../pages/account/components/AccountSetting'

var vendor_add, vendor_edit, vendor_view, vendor_logs
var category_add, category_logs, category_view
var subcategory_add, subcategory_logs, subcategory_view
var product_add, product_edit, product_view, product_logs
var requisition_type_add, requisition_type_logs, requisition_type_view
var requisition_add, requisition_edit, requisition_view, requisition_logs
var quotation_add, quotation_edit, quotation_view, quotation_logs
var purchaseorder_add, purchaseorder_edit, purchaseorder_view, purchaseorder_logs
var stock_add, stock_view, stock_logs, stock_edit
var department_add, department_logs, department_view
var user_add, user_edit, user_view, user_logs
var role_add, role_edit, role_view, role_logs

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  vendor_add = permissionCheck('supplier-create')
  vendor_edit = permissionCheck('supplier-update')
  vendor_view = permissionCheck('supplier-view')
  vendor_logs = permissionCheck('supplier-activity-log')

  category_add = permissionCheck('category-create')
  category_logs = permissionCheck('category-activity-log')
  category_view = permissionCheck('category-view')

  subcategory_add = permissionCheck('subcategory-create')
  subcategory_view = permissionCheck('subcategory-view')
  subcategory_logs = permissionCheck('subcategory-activity-log')

  product_add = permissionCheck('product-create')
  product_edit = permissionCheck('product-update')
  product_view = permissionCheck('product-view')
  product_logs = permissionCheck('product-activity-log')

  requisition_type_add = permissionCheck('requisitionType-create')
  requisition_type_view = permissionCheck('requisitionType-view')
  requisition_type_logs = permissionCheck('requisitionType-activity-log')

  requisition_add = permissionCheck('requisition-create')
  requisition_edit = permissionCheck('requisition-update')
  requisition_view = permissionCheck('requisition-view')
  requisition_logs = permissionCheck('requisition-activity-log')

  quotation_add = permissionCheck('quotation-create')
  quotation_edit = permissionCheck('quotation-update')
  quotation_view = permissionCheck('quotation-view')
  quotation_logs = permissionCheck('quotation-activity-log')

  purchaseorder_add = permissionCheck('purchaseOrder-create')
  purchaseorder_edit = permissionCheck('purchaseOrder-update')
  purchaseorder_view = permissionCheck('purchaseOrder-view')
  purchaseorder_logs = permissionCheck('purchaseOrder-activity-log')

  stock_add = permissionCheck('stock-create')
  stock_view = permissionCheck('stock-view')
  stock_edit = permissionCheck('stock-update')
  stock_logs = permissionCheck('stock-activity-log')

  department_add = permissionCheck('department-create')
  department_view = permissionCheck('department-view')
  department_logs = permissionCheck('department-activity-log')

  user_add = permissionCheck('user-create')
  user_edit = permissionCheck('user-update')
  user_view = permissionCheck('user-view')
  user_logs = permissionCheck('user-activity-log')

  role_add = permissionCheck('role-create')
  role_edit = permissionCheck('role-update')
  role_view = permissionCheck('role-view')
  role_logs = permissionCheck('role-activity-log')
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {vendor_add && (<Route path="/vendors/add-vendor" element={<AddVendor />} />)}
        {vendor_edit && (<Route path="/vendors/edit-vendor" element={<EditVendor />} />)}
        {vendor_view && (<Route path="/vendors/view-vendor" element={<ViewVendor />} />)}
        {vendor_logs && (<Route path="/vendors/view-vendor-logs" element={<ViewVendorLogs />} />)}
        {vendor_view && (<Route path="/vendors/vendors-list" element={<VendorsList />} />)}
        {vendor_logs && (<Route path="/vendors/vendor-logs" element={<VenderLogs />} />)}
        {vendor_logs && (<Route path="/vendors/all-vendors-logs" element={<AllVendorsLogs />} />)}
        {category_add && (<Route path="/categories/add-category" element={<AddCategory />} />)}
        {category_view && (<Route path="/categories/categories-list" element={<CategoriesList />} />)}
        {category_logs && (<Route path="/categories/category-logs" element={<CategoryLogs />} />)}
        {category_logs && (<Route path="/categories/all-categories-logs" element={<AllCategoriesLogs />} />)}
        {subcategory_add && (<Route path="/categories/subcategories/add-subcategory" element={<AddSubcategories />} />)}
        {subcategory_view && (<Route path="/categories/subcategories/subcategories-list" element={<SubcategoriesList />} />)}
        {subcategory_logs && (<Route path="/subcategories/subcategory-logs" element={<SubcategoryLogs />} />)}
        {subcategory_logs && (<Route path="/categories/subcategories/all-subcategories-logs" element={<AllSubcategoriesLogs />} />)}
        {product_add && (<Route path="/products/add-product" element={<AddProduct />} />)}
        {product_edit && (<Route path="/products/edit-product" element={<EditProduct />} />)}
        {product_view && (<Route path="/products/view-product" element={<ViewProduct />} />)}
        {product_view && (<Route path="/products/products-list" element={<ProductsList />} />)}
        {product_logs && (<Route path="/products/product-logs" element={<ProductLogs />} />)}
        {product_logs && (<Route path="/products/all-products-logs" element={<AllProductsLogs />} />)}
        {requisition_type_add && (<Route path="/requisition-type/add-requisition-type" element={<AddRequisitionType />} />)}
        {requisition_type_view && (<Route path="/requisition-type/requisition-type-list" element={<RequisitionTypeList />} />)}
        {requisition_type_logs && (<Route path="/requisition-type/requisition-type-logs" element={<RequisitionTypeLogs />} />)}
        {requisition_type_logs && (<Route path="/requisition-type/all-requisition-type-logs" element={<AllRequisitionTypesLogs />} />)}
        {requisition_add && (<Route path="/requisitions/add-requisition" element={<AddRequisition />} />)}
        {requisition_edit && (<Route path="/requisitions/edit-requisition" element={<EditRequisition />} />)}
        {requisition_view && (<Route path="/requisitions/view-requisition" element={<ViewRequisition />} />)}
        {requisition_view && (<Route path="/requisitions/requisitions-list" element={<RequisitionsList />} />)}
        {requisition_logs && (<Route path="/requisitions/requisition-logs" element={<RequisitionLog />} />)}
        {requisition_logs && (<Route path="/requisitions/all-requisitions-logs" element={<AllRequisitionsLogs />} />)}
        {requisition_add || requisition_edit ? (<Route path="/requisitions/requisition-document" element={<RequisitionDocuments />} />) : null}
        {/* <Route path="/quotation-type/add-quotation-type" element={<AddQuotationType />} />
        <Route path="/quotation-type/quotation-type-logs" element={<QuotationTypeLogs />} />
        <Route path="/quotation-type/all-quotations-type-logs" element={<AllQuotationsTypeLogs />} />
        <Route path="/quotation-type/quotation-type-list" element={<QuotationsTypeList />} /> */}
        {quotation_add && (<Route path="/quotations/add-quotation" element={<AddQuotation />} />)}
        {quotation_view && (<Route path="/quotations/quotations-list" element={<QuotationsList />} />)}
        {quotation_edit && (<Route path="/quotations/edit-quotation" element={<EditQuotation />} />)}
        {quotation_view && (<Route path="/quotations/view-quotation" element={<ViewQuotation />} />)}
        {quotation_logs && (<Route path="/quotations/quotation-logs" element={<QuotationLog />} />)}
        {quotation_logs && (<Route path="/quotations/all-quotations-logs" element={<AllQuotationLogs />} />)}
        {quotation_add || quotation_edit ? (<Route path="/quotations/quotation-document" element={<QuotationDocuments />} />) : null}
        {user_add && (<Route path="/users/add-user" element={<AddUser />} />)}
        {user_view && (<Route path="/users/users-list" element={<UsersList />} />)}
        {user_logs && (<Route path="/users/user-logs" element={<UserLogs />} />)}
        {user_edit && (<Route path="/users/edit-user" element={<EditUser />} />)}
        {user_view && (<Route path="/users/view-user" element={<ViewUser />} />)}
        {user_logs && (<Route path="/users/all-users-logs" element={<AllUsersLogs />} />)}
        {role_add && (<Route path="/users/roles/add-role" element={<AddRole />} />)}
        {role_edit && (<Route path="/users/roles/edit-role" element={<EditRole />} />)}
        {role_view && (<Route path="/users/roles/roles-list" element={<RolesList />} />)}
        {role_logs && (<Route path="/users/roles/all-roles-logs" element={<AllRolesLogs />} />)}
        {purchaseorder_add && (<Route path="/purchase-order/add-purchase-order" element={<AddPurchase />} />)}
        {purchaseorder_view && (<Route path="/purchase-order/purchase-order-list" element={<PurchasesList />} />)}
        {purchaseorder_view && (<Route path="/purchase-order/view-purchase-order" element={<ViewPurchase />} />)}
        {product_add && (<Route path='/purchase-order/add-new-product' element={<AddNewProduct />} />)}
        {purchaseorder_edit && (<Route path='/purchase-order/edit-purchase-order' element={<EditPurchase />} />)}
        {purchaseorder_logs && (<Route path='/purchase-order/purchase-order-logs' element={<PurchaseLogs />} />)}
        {purchaseorder_logs && (<Route path='/purchase-order/all-purchases-logs' element={<AllPurchasesLogs />} />)}
        {stock_add && (<Route path='/stocks/add-stock-out' element={<AddStockOut />} />)}
        {stock_edit && (<Route path='/stocks/edit-stock-in' element={<EditStockIn />} />)}
        {stock_add && (<Route path="/stocks/add-stock-in" element={<AddStockIn />} />)}
        {stock_view && (<Route path="/stocks/stock-in-list" element={<StockInList />} />)}
        {stock_view && (<Route path="/stocks/stock-out-list" element={<StockOutList />} />)}
        {stock_edit && (<Route path="/stocks/edit-stock-out" element={<EditStockOut />} />)}
        {stock_view && (<Route path="/stocks/view-stock-in" element={<ViewStockIn />} />)}
        {stock_view && (<Route path="/stocks/view-stock-out" element={<ViewStockOut />} />)}
        {stock_logs && (<Route path='/stocks/stock-in-logs' element={<StockInLogs />} />)}
        {stock_logs && (<Route path='/stocks/all-stock-in-logs' element={<AllStockInLogs />} />)}
        {stock_logs && (<Route path='/stocks/all-stock-out-logs' element={<AllStockOutLogs />} />)}
        {stock_logs && (<Route path='/stocks/stock-out-logs' element={<StockOutLogs />} />)}
        {department_add && (<Route path="/departments/add-department" element={<AddDepartment />} />)}
        {department_logs && (<Route path="/departments/department-logs" element={<DepartmentLogs />} />)}
        {department_logs && (<Route path="/departments/all-department-logs" element={<AllDepartmentLogs />} />)}
        {department_view && (<Route path="/departments/departments-list" element={<DepartmentsList />} />)}
        {product_view && (<Route path="/productTypes/assets-list" element={<AssetsList />} />)}
        {product_view && (<Route path="/productTypes/consumables-list" element={<ConsumablesList />} />)}
        <Route path="/generalSettings/general-setting" element={<GeneralSettings />} />
        <Route path="/accounts/update-profile" element={<ProfileUpdate/>} />
        <Route path="/accounts/account-setting" element={<AccountSetting/>} />
        {/* <Route path="/orders/order-add" element={<OrdersList />} />
        <Route path="/orders/order-edit" element={<OrdersList />} />
        <Route path="/orders/order-logs" element={<OrdersList />} />
        <Route path="/orders/order-logs" element={<OrdersList />} /> */}
        <Route path="/all-notifications" element={<AllNotifications />} />
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
