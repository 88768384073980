import axios from "axios";
import { config_multipart } from "../../api-helper/api-header-config";
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
// Password change
export const changePassword = async (passwordData: any) => {
    const response = await axios.post(`${baseURL}/api/change-password`, passwordData);
    return response.data;
  };
  // Get Single User Profile
export const get_single_user_profile = async () => {
  let response = await axios.get(baseURL + "/api/get-user-profile");
  return response.data;
}
// Update User Profile
export const update_user_profile = async (id:number,formData:object) => {
  const response = await axios.post(baseURL + "/api/update-user-profile/" + id, formData,config_multipart)
return response.data;
}