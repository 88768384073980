import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { KTIcon, KTSVG } from '../../../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { delete_requisition, get_all_requisitions, get_filtered_requisitions, get_requisitions_status } from '../api/requisitionsApi';
import { formatDateFlatpickr, formatDateWithTime, formatDateWithoutTime } from '../../../../helper-functions/DateFunction';
import { permissionCheck } from '../../../../helper-functions/PermissionCheck';
import Flatpickr from 'react-flatpickr';
import { get_all_requisition_type } from '../../requisitionsType/api/requisitionsTypeApi';

type Requisition = {
  id: number;
  title: string | undefined;
  description: string | undefined;
  due_date: string;
  status: string;
  requisition_type_id: number | undefined;
  requisition_type_title: string | undefined;
  updated_by_name: string;
  updated_at: string;
  requisition_products: {
    id: number;
    quantity: number;
    updated_by: string;
    updated_at: string;
    product_id: number;
    requisition_id: number;
  }[];
  requisition_assignees: {
    id: number;
    user_id: number;
    user_name: string;
    updated_by: string;
    updated_at: string;
    product_id: number;
    requisition_id: number;
  }[];
};
type RequisitionType = {
  id: number;
  title: string | undefined;
  updated_by: string | undefined;
  updated_by_name: string | undefined;
  updated_at: string;
}
var requisition_add, requisition_edit, requisition_view, requisition_logs, requisition_delete
function RequisitionsList() {
  const [searchInput, setSearchInput] = useState('');
  const [selectedId, setSelectedId] = useState(0);
  const [statusFlag, setStatusFlag] = useState('');
  const [requisitionList, setRequisitionList] = useState<Requisition[]>([]);
  const [selectedRequisition, setSelectedRequisition] = useState<Requisition | null>();
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleData, setVisibleData] = useState<Requisition[]>([]);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<{ key: string, ascending: boolean } | null>(null);
  const fromDateRef = useRef<Flatpickr>(null); 
  const toDateRef = useRef<Flatpickr>(null);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [requisitionTypeList, setRequisitionTypeList] = useState<RequisitionType[]>([]);
  const [requisitionTypeId, setRequisitionTypeId] = useState('');
  requisition_add = permissionCheck('requisition-create')
  requisition_edit = permissionCheck('requisition-update')
  requisition_view = permissionCheck('requisition-view')
  requisition_logs = permissionCheck('requisition-activity-log')
  requisition_delete = permissionCheck('requisition-delete')
  const { data: requisitionsData, isLoading: isRequisitionsLoading, isError: isRequisitionsError, refetch, } = useQuery('requisitions', get_all_requisitions, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (requisitionsData) {
      setRequisitionList(requisitionsData.data);
    };
  }, [requisitionsData]);

  const { data: requisitionsTypeData } = useQuery('requisitionsType', get_all_requisition_type, {refetchOnWindowFocus: false});
  useEffect(() => {
    if (requisitionsTypeData) {
      setRequisitionTypeList(requisitionsTypeData.data);
    };
  }, [requisitionsTypeData]);

  const { data: statusData } = useQuery(['RequisitionStatus', selectedId, statusFlag], () => get_requisitions_status(selectedId, statusFlag), {
    enabled: !!selectedId,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      refetch()
    }
  });
  const handleStatusAccepted = (id: number) => {
    setSelectedId(id);
    setStatusFlag('Accepted');
    toast.success('Status updated to Accepted', {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const handleStatusRejected = (id: number) => {
    setSelectedId(id);
    setStatusFlag('Rejected')
    toast.success('Status updated to Rejected', {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const navigate = useNavigate();
  const itemsPerPage = [10, 20, 30, 50, 100];
  const totalItems = requisitionList.length;
  const totalPages = Math.ceil(totalItems / selectedItemsPerPage)

  useEffect(() => {
    if (requisitionList.length > 0) {
      let sortedData = [...requisitionList];
      if (sortBy) {
        sortedData.sort((a, b) => {
          const aValue = (a[sortBy?.key] || '') as string;
          const bValue = (b[sortBy?.key] || '') as string;
          return sortBy && sortBy.ascending ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
      }
      setVisibleData(
        sortedData.slice(
          (currentPage - 1) * selectedItemsPerPage, // Update slice based on selected items per page
          currentPage * selectedItemsPerPage
        )
      );
    }
  }, [requisitionList, currentPage, selectedItemsPerPage, sortBy]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const searchTerm = e.currentTarget.value;
    setSearchInput(searchTerm);

    const filteredItems = requisitionsData.data.filter((requisition) => {
      return (
        requisition.title?.toLowerCase().includes(searchTerm) ||
        requisition.requisition_type_title?.toLowerCase().includes(searchTerm)
      );
    });

    setRequisitionList(filteredItems);
  };

  const handleDeleteRequisition = useMutation((id: number) => {
    return delete_requisition(id).then((response) => {
      toast.success(response.message, {
        position: "top-right",
        autoClose: 3000,
      });
    })
      .then(() => {
        return get_all_requisitions().then((data) => {
          setRequisitionList(data.data);
        });
      })
      .catch((error: any) => {
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          })
        }
      });
  });
  const handleDateFromChange = (date) => {
    if (date && date[0]) {
      const selectedDate = new Date(date[0]);
      selectedDate.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(selectedDate);
      setFromDate(formattedDate);

      // Update the "To Date" Flatpickr options
      if (toDateRef.current) {
        toDateRef.current.flatpickr.set('minDate', selectedDate);
      }
    } else {
      setFromDate('');
      // Reset the "To Date" picker minDate when "From Date" is cleared
      if (toDateRef.current) {
        toDateRef.current.flatpickr.set('minDate', null);
      }
    }
  };
  const handleDateToChange = (date) => {
    if (date && date[0]) {
      const selectedDate = new Date(date[0]);
      selectedDate.setHours(0, 0, 0, 0);
      const formattedDate = formatDateFlatpickr(selectedDate);
      setToDate(formattedDate);
    } else {
      setToDate('');
    }
  };
  const { data: filterData, refetch: filterRefetchRequisition } = useQuery(
    'filterData',
    () => get_filtered_requisitions(requisitionTypeId, fromDate, toDate),
    {
      refetchOnWindowFocus: false,
      onSuccess: (filteredData) => {
        setRequisitionList(filteredData.data);
      },
      onError: (error: any) => {
        // Error handling
        if (error.response) {
          const errorMessage = error.response.data.message;
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
      },
      enabled: false, // Set initial enable state to false
    }
  );
  const handleApplyFilters = () => {
    filterRefetchRequisition({ // Enable the query before refetch
      throwOnError: true,
    });
  };
  const handleClearFilters = () => {
    if (fromDateRef.current) {
      fromDateRef.current.flatpickr.clear();
    }
    if (toDateRef.current) {
      toDateRef.current.flatpickr.clear();
    }
  
    setFromDate('');
    setToDate('');
    setRequisitionTypeId('');
  
    if (requisitionsData) {
      setRequisitionList(requisitionsData.data);
    }
  };
  
  const handleSort = (key: string) => {
    if (sortBy && sortBy.key === key) {
      setSortBy({ key, ascending: !sortBy.ascending });
    } else if (!sortBy || sortBy.key !== key || sortBy.ascending) {
      setSortBy({ key, ascending: true });
    } else {
      setSortBy(null);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleRequisitionLogs = (requisition: any) => {
    navigate("/requisitions/requisition-logs", { state: { id: requisition.id } });
  };
  const handleEditRequisition = (requisition: any) => {
    navigate("/requisitions/edit-requisition", { state: { requisition: requisition } });
  }
  const handleViewRequisition = (requisition: any) => {
    navigate("/requisitions/view-requisition", { state: { id: requisition.id } });
  }
  const handleManageDocuments = (requisition: any) => {
    navigate("/requisitions/requisition-document", { state: { id: requisition.id } });
  }
  return (
    <div className='card card-xxl-stretch mx-4 mb-5 mb-xl-10'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Requisitions List</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {requisition_add && (
            <button
              data-toggle="tooltip" data-placement="bottom" title="Navigate to Add Requisition"
              className='btn btn-sm btn-light-dark'
              onClick={() => navigate('/requisitions/add-requisition')}
            >
              <KTIcon iconName='plus' className='fs-3' />
              Add Requisition
            </button>
          )}
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              data-toggle="tooltip" data-placement="bottom" title="Search a Requisition"
              type='text'
              data-kt-user-table-filter='search'
              className='form-control form-control-solid w-250px ps-14'
              placeholder='Search Requisition'
              value={searchInput}
              onChange={handleInputChange}
            />
          </div>
          {/* end::Search */}

          <div className='card-header border-0 pt-5 mx-2 d-flex flex-column align-items-end'>
            <div className='card-toolbar d-flex align-items-center'>
              <div>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  value={requisitionTypeId}
                  onChange={(e) => setRequisitionTypeId(e.target.value)}
                >
                  <option value=''>Select a Requisition Type...</option>
                  {Array.isArray(requisitionTypeList) && requisitionTypeList.length > 0 ? (
                    requisitionTypeList.map((requisitionType) => (
                      <option key={requisitionType.id} value={requisitionType.id}>
                        {requisitionType.title}
                      </option>
                    ))
                  ) : (
                    <option value='' disabled>No Requisition Type available</option>
                  )}
                </select>
              </div>
              <div className='mx-3'>
                <Flatpickr
                  ref={fromDateRef}
                  onChange={handleDateFromChange}
                  className='form-control form-control-solid'
                  placeholder='From Date'
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
              </div>
              <div>
                <Flatpickr
                  ref={toDateRef}
                  onChange={handleDateToChange}
                  className='form-control form-control-solid'
                  placeholder='To Date'
                  options={{
                    dateFormat: 'd/m/Y',
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-header border-0 pt-5 mx-3 d-flex flex-column align-items-end'>
            <div>
              <button
                className='btn btn-primary me-3'
                onClick={handleApplyFilters}
              >
                Apply Filters
              </button>
              <button
                className='btn btn-light-danger'
                onClick={handleClearFilters}
              >
                Clear Filters
              </button>
            </div>
          </div>
        </div>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Title <span onClick={() => handleSort('title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px '>Requisition Type <span onClick={() => handleSort('requisition_type_title')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Due Date <span onClick={() => handleSort('due_date')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-150px'>Documents</th>
                <th className='min-w-200px'>Updated By<span onClick={() => handleSort('updated_by_name')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                <th className='min-w-200px'>Updated At<span onClick={() => handleSort('updated_at')}> <KTIcon iconName='up-down' className='fs-4 cursor-pointer' /></span></th>
                {requisition_delete || requisition_edit || requisition_logs || requisition_view ? (
                  <th className='min-w-150px text-center'>Actions</th>
                ) : null}
                {requisition_edit || requisition_add ? (
                  <th className='min-w-100px text-center'>Status</th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {isRequisitionsError ? (
                <tr>
                  <td>Error loading data. Please try again later.</td>
                </tr>
              ) : isRequisitionsLoading ? (
                <tr>
                  <td colSpan={6}>
                    <div className='text-center'>
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ) : requisitionList.length > 0 ? (
                visibleData.map((requisition) => (
                  <tr key={requisition.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <h3 className="text-dark fw-bold cursor-pointer text-hover-success fs-6"
                            onClick={() => handleViewRequisition(requisition)}
                          >
                            {requisition.title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <h3 className="text-dark fw-bold cursor-pointer fs-6">
                            {requisition.requisition_type_title}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <h3 className="text-dark fw-bold cursor-pointer fs-6">
                            {formatDateWithoutTime(requisition.due_date)}
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start flex-column">
                          <h3 className="text-dark fw-bold cursor-pointer fs-6">
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Edit Requisition"
                              onClick={() => handleManageDocuments(requisition)}
                              className='btn btn-primary btn-light-dark btn-active-color-warning btn-sm me-1'
                            >
                              Manage Documents
                            </button>
                          </h3>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex flex-column w-100 me-2">
                        <div className="d-flex flex-stack mb-2 cursor-pointer">
                          {requisition.updated_by_name}
                        </div>
                      </div>
                    </td>
                    <td className="text-end">
                      <div className="d-flex flex-column w-100 me-2">
                        <div className="d-flex flex-stack mb-2 cursor-pointer">
                          {formatDateWithTime(requisition.updated_at)}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-end flex-shrink-0">
                        {requisition_edit && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Edit Requisition"
                            onClick={() => handleEditRequisition(requisition)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName="pencil" className="fs-3" />
                          </button>
                        )}
                        {requisition_view && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="See Requisition Detail"
                            onClick={() => handleViewRequisition(requisition)}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                          >
                            <KTIcon iconName='eye' className='fs-3' />
                          </button>
                        )}
                        {requisition_logs && (
                          <button
                            onClick={() => {
                              handleRequisitionLogs(requisition);
                            }}
                            className='btn btn-icon btn-light-dark btn-active-color-warning btn-sm me-1'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-custom-class='tooltip-dark'
                            title='Requisition Logs'
                          >
                            <i className='bi bi-journal-text'></i>
                          </button>
                        )}
                        {requisition_delete && (
                          <button
                            data-toggle="tooltip" data-placement="bottom" title="Delete Requisition"
                            onClick={() => setSelectedRequisition(requisition)}
                            className="btn btn-icon btn-light-dark btn-active-color-danger btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                          >
                            <KTIcon iconName="trash" className="fs-3" />
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      {requisition_edit || requisition_add ? (
                        <div className="d-flex justify-content-end flex-shrink-0">
                          {requisition.status === 'Accepted' ? (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Approve Requisition"
                              className="btn btn-icon btn-success  btn-sm me-1"
                            >
                              <KTIcon iconName="like" className="fs-3" />
                            </button>
                          ) : (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Approve Requisition"
                              onClick={() => {
                                handleStatusAccepted(requisition.id);
                              }}
                              className="btn btn-icon btn-light-dark btn-active-color-success btn-sm me-1"
                            >
                              <KTIcon iconName="like" className="fs-3" />
                            </button>
                          )}
                          {requisition.status === 'Rejected' ? (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Reject Requisition"
                              className="btn btn-icon btn-danger btn-active-color-danger btn-sm"
                            >
                              <KTIcon iconName='dislike' className='fs-3' />
                            </button>
                          ) : (
                            <button
                              data-toggle="tooltip" data-placement="bottom" title="Reject Requisition"
                              onClick={() => handleStatusRejected(requisition.id)}
                              className="btn btn-icon btn-light-dark btn-active-color-danger btn-sm"
                            >
                              <KTIcon iconName='dislike' className='fs-3' />
                            </button>
                          )}
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Products available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='card-footer d-flex justify-content-between py-4'>
        <div className='align-items-center row'>
          <div className='d-flex'>
            <div className='col-md-9 mt-5'><strong>Results Per Page:</strong></div>
            <select
              style={{ width: '60px' }}
              className='form-select form-select-md px-2 col-md-1'
              value={selectedItemsPerPage}
              onChange={(e) => setSelectedItemsPerPage(Number(e.target.value))}
            >
              {itemsPerPage.map(option => (
                <option key={option} value={option} className='p-3 '>{option}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          {currentPage === 1 ? (
            <button
              className='btn btn-light'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          ) : (
            <button
              className='btn btn-primary'
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          )}
          <button
            className='btn btn-primary ms-2'
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
      {/* delete modal */}
      <div className="modal fade" tabIndex={-1} id="kt_modal_1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Delete Requisition Confirmation</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body">
              {selectedRequisition ? (
                <>
                  <p className="mb-2">
                    <span className="fw-bold">Requisition Title: </span> {selectedRequisition.title}
                  </p>
                  <p className="mb-2">
                    <span className="fw-bold">Due Date: </span> {formatDateWithoutTime(selectedRequisition.due_date)}
                  </p>
                </>
              ) : null}
            </div>
            <div className="modal-footer">
              <button data-toggle="tooltip" data-placement="bottom" title="close" type="button" className="btn btn-light" data-bs-dismiss="modal">
                Close
              </button>
              <button
                data-toggle="tooltip" data-placement="bottom" title="Delete this Requisition"
                type="button"
                onClick={() => {
                  if (selectedRequisition && selectedRequisition.id) {
                    handleDeleteRequisition.mutate(selectedRequisition.id);
                  }
                }}
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Delete Requisition
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequisitionsList
